import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "slick-carousel/slick/slick.css";
import ScrollToTop from "./components/elements/ScrollToTop";
import { store } from "./redux/store";
import AllRoutes from "./router/AllRoutes";
import { BackButton } from "./components/BackButton";
import { checkToken } from "./assets/dummyData/axiosCalls";

function App() {
	// aos animation activation

	useEffect(() => {
		AOS.init({
			duration: 1200,
		});
	}, []);

	useEffect(() => {
		try {
			checkToken()
				.then((res) => {
					if (res && res.status === 200) {
						console.log("oK tok");
					} else {
						sessionStorage.removeItem("token");
					}
				})
				.catch((err) => {
					console.error(err);
				});
		} catch (error) {
			console.error(error);
		}
	}, []);

	return (
		<Provider store={store}>
			<div className="h-100">
				<AllRoutes />
				<BackButton />
				<ScrollToTop />
				<ToastContainer />
			</div>
		</Provider>
	);
}

export default App;
