import React, { useState } from "react";
import { getLang } from "../../assets/dummyData/langs";
import { useSelector } from "react-redux";

export const MiddleCgoal = () => {
	const [langs] = useState(getLang());
	const language = useSelector((state) => state.language.value);

	return (
		<div className="bg-deep-cyan vcamp-text-block-eight  vcamp-feature-section-nine pt-100 lg-pt-50 h-100">
			<div className="container">
				<div className="row">
					<div className="col-lg-7 col-md-6 col-sm-8 ms-auto">
						<div className=" white-vr text-end text-md-start" data-aos="fade-left">
							<div className="text-wrapper">
								<h2>{langs.get("lb_myGoalTitle")[language]}</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
