import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getLang } from "../../assets/dummyData/langs";
import { useSelector } from "react-redux";

const customStyles = {
	control: (provided, state) => ({
		...provided,
		border: "1px solid #339999",
		paddingLeft: "1.2rem",
		"&:hover": {
			borderColor: "none",
		},
		"&:select": {
			borderColor: "none",
		},
	}),
	option: (provided, state) => ({
		...provided,
	}),
	input: (provided, state) => ({
		...provided,
		color: "transparent",
	}),
	singleValue: (provided, state) => ({
		...provided,
		color: "dark",
		fontSize: "1rem",
	}),
};

export const SelectCotactForm = ({ cb }) => {
	const [langs] = useState(getLang());
	const language = useSelector((state) => state.language.value);

	const [options, setOptions] = useState([
		{ value: langs.get("lb_DigitalMarketing")[language], label: langs.get("lb_DigitalMarketing")[language] },
		{ value: langs.get("lb_WebDesign")[language], label: langs.get("lb_WebDesign")[language] },
		{ value: langs.get("lb_WebAnalytics")[language], label: langs.get("lb_WebAnalytics")[language] },
		{ value: langs.get("lb_WebProgramming")[language], label: langs.get("lb_WebProgramming")[language] },
		{ value: langs.get("lb_Price")[language], label: langs.get("lb_Price")[language] },
		{ value: langs.get("lb_Information")[language], label: langs.get("lb_Information")[language] },
	]);

	useEffect(() => {
		setOptions([
			{ value: langs.get("lb_DigitalMarketing")[language], label: langs.get("lb_DigitalMarketing")[language] },
			{ value: langs.get("lb_WebDesign")[language], label: langs.get("lb_WebDesign")[language] },
			{ value: langs.get("lb_WebAnalytics")[language], label: langs.get("lb_WebAnalytics")[language] },
			{ value: langs.get("lb_WebProgramming")[language], label: langs.get("lb_WebProgramming")[language] },
			{ value: langs.get("lb_Price")[language], label: langs.get("lb_Price")[language] },
			{ value: langs.get("lb_Information")[language], label: langs.get("lb_Information")[language] },
		]);
	}, [language, langs]);

	return (
		<div className="input-group-meta form-group mb-25 d-flex flex-column">
			<label>{langs.get("lb_SelectService")[language]}*</label>
			<Select
				theme={(theme) => ({
					...theme,
					borderRadius: 0,
					spacing: {
						menuGutter: 1,
					},
					colors: {
						primary: "#339999",
						primary50: "#ccc",
						primary25: "#ccc",
						danger: "#ccc",
						dangerLight: "#ccc",
						neutral5: "#ccc",
						neutral10: "#ccc",
						neutral20: "#ccc",
						neutral30: "#ccc",
						neutral40: "#ccc",
						neutral50: "#ccc",
						neutral60: "#ccc",
						neutral70: "#ccc",
						neutral80: "#ccc",
						neutral90: "#ccc",

						primary75: "#fff",
						neutral0: "#fff",
					},
				})}
				classNamePrefix="reSel"
				styles={customStyles}
				className="reSelect"
				defaultValue={{ value: langs.get("lb_Information")[language], label: langs.get("lb_Information")[language] }}
				options={options}
				onChange={(e) => cb(e.value)}
				name="category"
				required="required"
			/>
		</div>
	);
};
