import React, { useState } from "react";
import { getLang } from "../../../../assets/dummyData/langs";
import { useSelector } from "react-redux";

const MobileMenuContent = () => {
	const [langs] = useState(getLang());
	const language = useSelector((state) => state.language.value);

	const handleSubmit = (event) => {
		event.preventDefault();
	};

	return (
		<>
			<form onClick={handleSubmit} className="search-form">
				<input type="text" placeholder={langs.get("lb_menuSearchHere")[language]} />
				<button>
					<i className="bi bi-search"></i>
				</button>
			</form>

			<div className="address-block">
				<h4 className="title">{langs.get("lb_menuOurAddress")[language]}</h4>
				<p>
					Hodalová 10 841 04 <br />
					Bratislava, Slovakia
				</p>
			</div>
		</>
	);
};

export default MobileMenuContent;
