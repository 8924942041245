import React, { useState } from "react";
import { Link } from "react-router-dom";
import MobileMenuContent from "../common/header/mega-menu/MobileMenuContent";
import ScrollspyNav from "react-scrollspy-nav";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../../redux/features/language-slice";
import { getLang } from "../../assets/dummyData/langs";

const Header = () => {
	const [navbar, setNavbar] = useState(false);
	const [langs] = useState(getLang());
	const language = useSelector((state) => state.language.value);
	const dispatch = useDispatch();

	const changeBackground = () => {
		if (window.scrollY >= 95) {
			setNavbar(true);
		} else {
			setNavbar(false);
		}
	};
	const handleChangeLanguage = () => {
		let newLanguage = language === "eng" ? "sk" : "eng";
		dispatch(changeLanguage(newLanguage));
	};

	window.addEventListener("scroll", changeBackground);

	const menuContent = [
		{ itemName: langs.get("lb_menuAboutUs")[language], hrefId: "#about" },
		{ itemName: langs.get("lb_menuJoinUs")[language], hrefId: "#join-us" },
		{ itemName: langs.get("lb_menuGallery")[language], hrefId: "#gallery" },
		{ itemName: langs.get("lb_menuContact")[language], hrefId: "#contact" },
	];

	return (
		<header className={`theme-main-menu sticky-menu theme-menu-five ${navbar ? "fixed" : ""} `}>
			<div className="inner-content">
				<div className="d-flex align-items-center justify-content-lg-center justify-content-between">
					<button className="d-none d-lg-block theme-btn-twelve ripple-btn position-absolute start-0" onClick={handleChangeLanguage}>
						{language === "eng" ? "ENG" : "SVK"}
					</button>
					<div className="logo">
						<Link to="/">
							<img src={require("../../assets/images/svg/logolike.svg").default} alt="brand" />
						</Link>
					</div>
					{/* End .logo */}

					<nav className="navbar navbar-expand-lg">
						<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
							<span></span>
						</button>
						<div className="collapse navbar-collapse dark-style" id="navbarNav">
							<ScrollspyNav scrollTargetIds={["about", "join-us", "gallery", "contact"]} offset={0} activeNavClass="active" scrollDuration="100" headerBackground="true">
								<button className=" d-lg-none theme-btn-twelve ripple-btn w-100 mb-3" onClick={handleChangeLanguage}>
									{language === "eng" ? "ENG" : "SVK"}
								</button>
								<ul className="navbar-nav">
									{menuContent.map((list, i) => (
										<li className="nav-item" key={i}>
											<a className="nav-link" href={list.hrefId}>
												{list.itemName}
											</a>
										</li>
									))}
								</ul>
							</ScrollspyNav>

							{/* End .navbar-nav */}

							<div className="mobile-content d-block d-lg-none">
								<MobileMenuContent />
							</div>
							{/* <!-- /.mobile-content --> */}
						</div>
					</nav>
				</div>
			</div>
			{/* <!-- /.inner-content --> */}
		</header>
	);
};

export default Header;
