import React, { useState } from "react";
import { getLang } from "../../../assets/dummyData/langs";
import { useSelector } from "react-redux";

const FooterNewsletter = () => {
	const [langs] = useState(getLang());
	const language = useSelector((state) => state.language.value);

	const handleSubmit = (event) => {
		event.preventDefault();
	};

	return (
		<div className="newsletter">
			<h5 className="title">Newslettert</h5>
			<p>{langs.get("lb_msgNewsletter")[language]}</p>

			<form onClick={handleSubmit}>
				<input type="email" placeholder={langs.get("lb_msgEnterEmail")[language]} />
				<button className="btn ripple-btn text-nowrap w-auto">{langs.get("lb_singup")[language]}</button>
			</form>
			{/* End form */}

			<div className="info ">{langs.get("lb_msgNewsletterRelevant")[language]}</div>
		</div>
	);
};

export default FooterNewsletter;
