import React, { useState } from "react";
import Team from "./Team";
import { getLang } from "../../assets/dummyData/langs";
import { useSelector } from "react-redux";

export const WorkGalery = () => {
	const [langs] = useState(getLang());
	const language = useSelector((state) => state.language.value);

	return (
		<div className="vcamp-text-block-nine pt-180 lg-pt-120" id="gallery">
			<div className="container">
				<div className="row">
					<div className="col-12 col-lg-9 m-auto">
						<div className="text-wrapper text-center pb-20 lg-p0">
							<h2 className="title">{langs.get("lb_loveOurWork")[language]}</h2>
							<p className="text-lg">{langs.get("lb_misionQualityWeb")[language]}</p>
							<p className="text-lg p-0">{langs.get("lb_choseLayoutText")[language]}</p>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-xxl-12 m-auto">
						<div className="row">
							<Team />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
