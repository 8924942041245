import { useState } from "react";
import { useSelector } from "react-redux";
import { getLang } from "../assets/dummyData/langs";
import notFoundImg from "../assets/images/svg/wrongWay.svg";
import Seo from "../components/common/seo/Seo";

function NotFound() {
	const [langs] = useState(getLang());
	const language = useSelector((state) => state.language.value);
	return (
		<>
			<Seo title="404 " />
			<div className="bg-deep-cyan error-page d-flex align-items-end justify-content-center ">
				<div className="content-wrapper hero-banner-eight">
					<div className="d-flex justify-content-center">
						<img src={notFoundImg} alt="" className="img-fluid w-50" />
					</div>
					<h6 className="text-center text-sm-start">404</h6>
					<h1 className="hero-heading text-center text-sm-start">{langs.get("lb_PageNotFound")[language]} </h1>
					<p className="hero-sub-heading text-lg text-center text-sm-start">{langs.get("lb_msgReturnHome")[language]}</p>
				</div>
			</div>
		</>
	);
}

export default NotFound;
