import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	value: sessionStorage.getItem("token") && localStorage.getItem("logedUser") ? localStorage.getItem("logedUser") : null,
};

export const logedUserSlice = createSlice({
	name: "logedUser",
	initialState,
	reducers: {
		setUser: (state, { payload }) => {
			state.value = payload;
		},
	},
});

export const { setUser } = logedUserSlice.actions;

export default logedUserSlice.reducer;
