/**
 * INITIALS
 * 	const [langs] = useState(getLang());
 * 	const language = useSelector((state) => state.language.value);
 * 	{langs.get("lb_welcomeTitle")[language]}
 */

export const getLang = () => {
	const langs = new Map();

	langs.set("lb_welcomeTitle", {
		eng: "We help shape your ideas and turn them into.",
		sk: "Pomáhame premieňať vaše nápady na skutočnosť.",
	});
	langs.set("lb_aboutUsTitle", {
		eng: "We pushing your business.",
		sk: "Podporujeme rast vašej spoločnosti.",
	});
	langs.set("lb_aboutUsSubtitle", {
		eng: "We are a start-up studio focused on web development. Our goal is to prepare with the best, connect them with ideas and integrate them into a unique ecosystem.",
		sk: "Sme start-up štúdio zamerané na tvorbu webu. Našim cieľom je spolupracovať s najlepšími, spájať ich s nápadmi a integrovať ich do jedinečného ekosystému.",
	});
	langs.set("lb_aboutUsAuthSubtitle", {
		eng: "We turn ideas into reality together.",
		sk: "Spoločne premieňame nápady na skutočnosť.",
	});
	langs.set("lb_oweDescribeSubtitle", {
		eng: "Owe like our website",
		sk: "Owe like our website",
	});
	langs.set("lb_videoTitle", {
		eng: "We are there for you.",
		sk: "Sme tu pre vás.",
	});
	langs.set("lb_videoSubtitle", {
		eng: "We are helping client to create Business with our talented expert.",
		sk: "Pomáhame klientovi vytvoriť obchod s naším talentovaným odborníkom.",
	});
	langs.set("lb_notAMember", {
		eng: "Not a member yet?",
		sk: "Ešte nie ste členom?",
	});
	langs.set("lb_keepLogin", {
		eng: "Keep me logged in!",
		sk: "Nechaj ma prihláseného!",
	});
	langs.set("lb_login", {
		eng: "Log in",
		sk: "Prihlásiť sa",
	});
	langs.set("lb_singup", {
		eng: "Sign up",
		sk: "Zaregistrovať sa",
	});
	langs.set("lb_forgetPass", {
		eng: "Forget Password?",
		sk: "Zabudnuté heslo?",
	});
	langs.set("lb_ourProductHeart", {
		eng: "Our creative company is product of quality, tact, & heart.",
		sk: "Naša kreatívna spoločnosť je produktom kvality, taktu a srdca.",
	});
	langs.set("lb_startStudioMission", {
		eng: "I started startup studio with a mission to partner with creative founders connecting them with ideas and placing them on network.",
		sk: "Založil som startup štúdio s poslaním spolupracovať s kreatívnymi zakladateľmi, spájať ich s nápadmi a umiestňovať ich na sieť.",
	});
	langs.set("lb_myGoalTitle", {
		eng: "My goal is your success.",
		sk: "Mojím cieľom je váš úspech.",
	});
	langs.set("lb_loveOurWork", {
		eng: "We love our work.",
		sk: "Milujeme našu prácu.",
	});
	langs.set("lb_misionQualityWeb", {
		eng: "The mission of our company is to provide clients with quality websites that will be the basis of their online strategy.",
		sk: "Poslaním našej spoločnosti je poskytovať klientom kvalitné webové stránky, ktoré budú základom ich online stratégie.",
	});

	langs.set("lb_choseLayoutText", {
		eng: "Choose from our ready-made layouts.",
		sk: "Vyberte si z našich hotových rozložení.",
	});
	langs.set("lb_btnBack", {
		eng: "Back",
		sk: "Späť",
	});
	langs.set("lb_menuAboutUs", {
		eng: "About us",
		sk: "O nás",
	});
	langs.set("lb_menuJoinUs", {
		eng: "Join us",
		sk: "Pripoj sa k nám",
	});
	langs.set("lb_menuGallery", {
		eng: "Gallery",
		sk: "Galéria",
	});
	langs.set("lb_menuContact", {
		eng: "Contact",
		sk: "Kontakt",
	});
	langs.set("lb_menuOurAddress", {
		eng: "Our Address",
		sk: "Naša adresa",
	});
	langs.set("lb_menuSearchHere", {
		eng: "Search here..",
		sk: "Hľadaj tu..",
	});
	langs.set("lb_PageNotFound", {
		eng: "Page Not Found",
		sk: "Stránka nenájdená",
	});
	langs.set("lb_msgReturnHome", {
		eng: "If you want to continue, return to the home page.",
		sk: "Ak chcete pokračovať, vráťte sa na domovskú stránku.",
	});
	langs.set("lb_TermsOfUse", {
		eng: "Terms of Use",
		sk: "Podmienky používania",
	});
	langs.set("lb_TermsConditions", {
		eng: "Terms & Conditions",
		sk: "Všeobecné Podmienky",
	});
	langs.set("lb_TermsConditions", {
		eng: "Terms & Conditions",
		sk: "Všeobecné Podmienky",
	});
	langs.set("lb_PrivacyPolicy", {
		eng: "Privacy Policy",
		sk: "Zásady ochrany osobných údajov",
	});
	langs.set("lb_CookiePolicy", {
		eng: "Cookie Policy",
		sk: "Zásady používania súborov cookie",
	});
	langs.set("lb_PrivacyTerms", {
		eng: "Privacy & Terms.",
		sk: "Ochrana osobných údajov a podmienky.",
	});
	langs.set("lb_msgNewsletter", {
		eng: "If you want to know all the news about us.",
		sk: "Ak chcete vedieť všetky novinky o nás.",
	});
	langs.set("lb_msgNewsletterRelevant", {
		eng: "We only send interesting and relevant emails.",
		sk: "Posielame iba zaujímavé a relevantné e-maily.",
	});
	langs.set("lb_msgEnterEmail", {
		eng: "Enter your email",
		sk: "Zadajte svoj e-mail",
	});
	langs.set("lb_youAreIn", {
		eng: "You are logged in",
		sk: "Ste prihlásený",
	});
	langs.set("lb_ourAddress", {
		eng: "Our Address",
		sk: "Naša adresa",
	});
	langs.set("lb_contactInfo", {
		eng: "Contact Info",
		sk: "Kontaktné informácie",
	});
	langs.set("lb_leaveMsg", {
		eng: "Leave us a message",
		sk: "Zanechajte nám správu",
	});
	langs.set("lb_btnContactForm", {
		eng: "Contact Form",
		sk: "Kontaktný formulár",
	});
	langs.set("lb_FirstName", {
		eng: "First Name",
		sk: "Krstné meno",
	});
	langs.set("lb_LastName", {
		eng: "Last Name",
		sk: "Priezvisko",
	});
	langs.set("lb_Message", {
		eng: "Message",
		sk: "Správa",
	});
	langs.set("lb_YourMessage", {
		eng: "Your message",
		sk: "Vaša správa",
	});
	langs.set("lb_btnSendMsg", {
		eng: "Send Message",
		sk: "Poslať správu",
	});
	langs.set("lb_SelectService", {
		eng: "Select Service",
		sk: "Vyberte Službu",
	});
	langs.set("lb_Information", {
		eng: "Information",
		sk: "Informácie",
	});
	langs.set("lb_DigitalMarketing", {
		eng: "Digital Marketing",
		sk: "Digitálny marketing",
	});
	langs.set("lb_WebDesign", {
		eng: "Web Design",
		sk: "Vzhľad stránky",
	});
	langs.set("lb_WebAnalytics", {
		eng: "Web Analytics",
		sk: "Webová analýza",
	});
	langs.set("lb_WebProgramming", {
		eng: "Web Programming",
		sk: "Webové programovanie",
	});
	langs.set("lb_Price", {
		eng: "Price",
		sk: "Cena",
	});
	langs.set("lb_msgSendSuccess", {
		eng: "Message was succesfully sent",
		sk: "Správa bola úspešne odoslaná",
	});
	langs.set("lb_msgSendError", {
		eng: "Nepodarilo sa odoslať",
		sk: "Failed to send",
	});

	return langs;
};
