import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getLang } from "../../assets/dummyData/langs";

export const MiddleCquiality = () => {
    	const [langs] = useState(getLang());
    	const language = useSelector((state) => state.language.value);
    	
   
   return (
      <div className="bg-deep-cyan vcamp-text-block-eight  vcamp-feature-section-nine pt-100 lg-pt-50 h-100">
         <div className="container">
            <img className="d-none d-xl-block position-absolute   w-100 h-100  bottom-50  img-fluid" src={require("../../assets/images/svg/logohalf.svg").default} alt="brand" />
            <div className="row">
               <div className="col-12 text-center">
                  <div className="text-wrapper">
                     <h2>{langs.get("lb_ourProductHeart")[language]}</h2>
                  </div>
                  {/* <!-- /.text-wrapper --> */}
               </div>
            </div>
         </div>
         {/* End .container */}
      </div>
   );
};
