import { combineReducers } from "@reduxjs/toolkit";
import cartSlice from "./features/cart-slice";
import productSlice from "./features/product-slice";
import wishlistSlice from "./features/wishlist-slice";
import urlSlice from "./oweFeatures/url-slice";
import languageSlice from "./features/language-slice";
import logedUserSlice from "./features/logedUser-slice";

const rootReducer = combineReducers({
	products: productSlice,
	cart: cartSlice,
	wishlist: wishlistSlice,
	url: urlSlice,
	language: languageSlice,
	logedUser: logedUserSlice,
});

export default rootReducer;
