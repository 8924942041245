import home01 from "../../../../../assets/images/nav-img/home01.jpg";
import home02 from "../../../../../assets/images/nav-img/home02.jpg";
import home03 from "../../../../../assets/images/nav-img/home03.jpg";
import home04 from "../../../../../assets/images/nav-img/home04.jpg";
import home05 from "../../../../../assets/images/nav-img/home05.jpg";
import home15 from "../../../../../assets/images/nav-img/home08.jpg";
import home06 from "../../../../../assets/images/nav-img/home10.jpg";
import home07 from "../../../../../assets/images/nav-img/home11.jpg";
import home08 from "../../../../../assets/images/nav-img/home13.jpg";
import home09 from "../../../../../assets/images/nav-img/home14.jpg";
import home10 from "../../../../../assets/images/nav-img/home15.jpg";
import home11 from "../../../../../assets/images/nav-img/home18.jpg";

const homeMegaMenuData = [
	{
		id: 1,
		img: home02,
		title: "Creative Agency",
		routeLink: "/creative-agency",
		comingSoonClass: "",
		comingSoon: "",
		cursorEvent: "",
		dealyAnimTime: 0,
	},
	{
		id: 2,
		img: home01,
		title: "Business",
		routeLink: "/business",
		comingSoonClass: "",
		comingSoon: "",
		cursorEvent: "",
		dealyAnimTime: 100,
	},
	{
		id: 3,
		img: home03,
		title: "Agency Minimal",
		routeLink: "/agency-minimal",
		comingSoonClass: "",
		comingSoon: "",
		cursorEvent: "",
		dealyAnimTime: 200,
	},
	{
		id: 4,
		img: home04,
		title: "Service Provider",
		routeLink: "/service-provider",
		comingSoonClass: "",
		comingSoon: "",
		cursorEvent: "",
		dealyAnimTime: 300,
	},
	{
		id: 5,
		img: home05,
		title: "Startup",
		routeLink: "/startup",
		comingSoonClass: "",
		comingSoon: "",
		cursorEvent: "",
		dealyAnimTime: 100,
	},
	{
		id: 6,
		img: home06,
		title: "Startup Agency",
		routeLink: "/",
		comingSoonClass: "",
		comingSoon: "",
		cursorEvent: "",
		dealyAnimTime: 200,
	},
	{
		id: 7,
		img: home07,
		title: "Consulting Agency",
		routeLink: "/consulting-agency",
		comingSoonClass: "",
		comingSoon: "",
		cursorEvent: "",
		dealyAnimTime: 300,
	},
	{
		id: 8,
		img: home08,
		title: "Photographer Portfolio",
		routeLink: "/photographer-portfolio",
		comingSoonClass: "",
		comingSoon: "",
		cursorEvent: "",
		dealyAnimTime: 100,
	},
	{
		id: 9,
		img: home09,
		title: "Designer Portfolio",
		routeLink: "/designer-portfolio",
		comingSoonClass: "",
		comingSoon: "",
		cursorEvent: "",
		dealyAnimTime: 200,
	},
	{
		id: 10,
		img: home10,
		title: "Marketing Agency",
		routeLink: "/marketing-agency",
		comingSoonClass: "",
		comingSoon: "",
		cursorEvent: "",
		dealyAnimTime: 300,
	},
	{
		id: 11,
		img: home11,
		title: "Design Agency Portfolio",
		routeLink: "/design-agency-portfolio",
		comingSoonClass: "",
		comingSoon: "",
		cursorEvent: "",
		dealyAnimTime: 100,
	},
	{
		id: 12,
		img: home15,
		title: "Shop Standard",
		routeLink: "/shop-standard",
		comingSoonClass: "",
		comingSoon: "",
		cursorEvent: "",
		dealyAnimTime: 200,
	},
];

export default homeMegaMenuData;
