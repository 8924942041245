import React from "react";
import { Link } from "react-router-dom";
import teamContent from "./teamContent.js";
import CustomLink from "../common/header/mega-menu/CustomLink.jsx";
import homeDropdownData from "../common/header/mega-menu/dropdown-data/homeMegaMenuData";

const Team = () => {
	const handleScroll = () => {
		window.scrollTo(0, 0);
	};

	const contentRender = () => {
		let content = [];
		if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i)) {
			content = teamContent.slice(0, 2);
			content.push({ id: 999, btnText: "More preview" });
		} else {
			content = teamContent;
		}

		return (
			<div className="row gx-3">
				{homeDropdownData.map((val, idx) => (
					<div className="col-12 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-delay={val.dealyAnimTime} key={val.id + idx}>
						<div className="team-block-three">
							<Link to={val.routeLink} className="w-100 shadow" onClick={handleScroll}>
								<img src={val.img} alt="homeimage" className=" w-100 img-fluid" />
							</Link>
							<CustomLink to={val.routeLink} className="dropdown-item">
								<h3 className="name">{val.title}</h3>
							</CustomLink>
						</div>
					</div>
				))}
			</div>
		);
	};

	return <>{contentRender()}</>;
};

export default Team;
