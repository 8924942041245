import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getLang } from "../assets/dummyData/langs";

export const BackButton = () => {
	const [langs] = useState(getLang());
	const language = useSelector((state) => state.language.value);

	const location = useLocation();

	return (
		location.pathname !== "/" && (
			<Link to="/" className="position-fixed fixed-bottom p-10  theme-btn-twelve ripple-btn  end-0">
				{langs.get("lb_btnBack")[language]}
			</Link>
		)
	);
};
