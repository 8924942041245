import React from "react";

const Social = () => {
	const socialContent = [
		{
			id: 1,
			iconName: "fab fa-facebook-f",
			socialLink: "https://www.facebook.com/groups/849407753525963",
		},
		{
			id: 2,
			iconName: "bi bi-instagram",
			socialLink: "https://www.instagram.com/owe.krasnansky/",
		},
	];
	return (
		<>
			{socialContent.map((social) => (
				<li key={social.id}>
					<a href={social.socialLink} target="_blank" rel="noopener noreferrer">
						<i className={` ${social.iconName}`}></i>
					</a>
				</li>
			))}
		</>
	);
};

export default Social;
