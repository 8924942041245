import React, { useCallback, useState } from "react";
import { SelectCotactForm } from "../../startup-agency/SelectCotactForm";
import { getLang } from "../../../assets/dummyData/langs";
import { useSelector } from "react-redux";
import { sendContactMail } from "../../../assets/dummyData/axiosCalls";
import { motion } from "framer-motion";

const HeaderModal = () => {
	const [langs] = useState(getLang());
	const language = useSelector((state) => state.language.value);

	const [stateInputs, setStateInputs] = useState({ fname: "", lname: "", mail: "", msg: "" });
	const [service, setService] = useState("info default");
	const [message, setMessage] = useState("");

	const handleSetService = (e) => {
		setService(e);
	};

	const handleSubmitForm = (e) => {
		setMessage(null);
		e.preventDefault();

		let sendObj = { ...stateInputs, service };

		try {
			sendContactMail(sendObj)
				.then((res) => {
					if (res === 500 || res === 400) throw new Error("ERR CONNECTION");
					setMessage(langs.get("lb_msgSendSuccess")[language]);
					return res;
				})
				.catch((err) => {
					console.log(err, "FRONT");
					setMessage(langs.get("lb_msgSendError")[language]);
				});
		} catch (error) {
			console.log(error, "FRONT TRY");
			setMessage(langs.get("lb_msgSendError")[language]);
		}
	};

	const renderSendMsg = useCallback(() => {
		return (
			!!message && (
				<motion.div
					className="box"
					initial={{ opacity: 0, scale: 0.5 }}
					animate={{ opacity: 1, scale: 1 }}
					transition={{
						duration: 1.3,
						ease: [0, 0.71, 0.2, 1.01],
						scale: {
							type: "spring",
							damping: 5,
							stiffness: 1000,
							restDelta: 0.001,
						},
					}}
				>
					<div>{message}</div>
				</motion.div>
			)
		);
	}, [message]);
	return (
		<div className="modal-content">
			<div className="modal-header bg-primary text-white">
				<div className="row align-items-center w-100">
					<div className="col">{langs.get("lb_btnContactForm")[language]}</div>
					<div className="col-auto ">{renderSendMsg()}</div>
					<div className="col-auto ">
						<button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
				</div>
			</div>
			<div className="modal-body px-0 p-md-2 p-xl-5">
				<div className="container">
					<div className="row">
						<div className="col-xl-11 m-auto">
							<div className="user-data-form">
								<form onSubmit={handleSubmitForm}>
									<div className="row controls">
										<div className="col-sm-6">
											<div className="input-group-meta form-group mb-25">
												<label>{langs.get("lb_FirstName")[language]}*</label>
												<input
													type="text"
													placeholder={langs.get("lb_FirstName")[language]}
													name="name"
													required
													value={stateInputs.fname}
													onChange={(e) => setStateInputs({ ...stateInputs, fname: e.target.value })}
													data-error="Name is required."
												/>
												<div className="help-block with-errors"></div>
											</div>
										</div>

										<div className="col-sm-6">
											<div className="input-group-meta form-group mb-25">
												<label>{langs.get("lb_LastName")[language]}*</label>
												<input
													type="text"
													placeholder={langs.get("lb_LastName")[language]}
													required
													name="Lname"
													value={stateInputs.lname}
													onChange={(e) => setStateInputs({ ...stateInputs, lname: e.target.value })}
												/>
											</div>
										</div>

										<div className="col-12">
											<div className="input-group-meta form-group mb-25">
												<label>Email*</label>
												<input type="email" required placeholder="owe@owe.com" name="email" value={stateInputs.mail} onChange={(e) => setStateInputs({ ...stateInputs, mail: e.target.value })} />
											</div>
										</div>
										<div className="col-12">
											<SelectCotactForm cb={handleSetService} />
										</div>
										<div className="col-12">
											<label>{langs.get("lb_Message")[language]}</label>
											<div className="input-group-meta form-group mb-25 ">
												<textarea
													className="form-control rounded-0 border-primary"
													value={stateInputs.msg}
													required
													rows={5}
													placeholder={`${langs.get("lb_YourMessage")[language]}*`}
													name="message"
													onChange={(e) => setStateInputs({ ...stateInputs, msg: e.target.value })}
												></textarea>
											</div>
										</div>

										<div className="col-12 text-end">
											<div className="row align-items-center">
												<div className="col d-none d-sm-block">
													<img className="img-fluid h-50" src={require("../../../assets/images/svg/logolike.svg").default} alt="brand" />
												</div>
												<div className="col col-sm-auto">
													<div className="d-flex justify-content-end">
														<button className="theme-btn-nine ripple-btn text-nowrap w-100">{langs.get("lb_btnSendMsg")[language]}</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeaderModal;
