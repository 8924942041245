import React from "react";

const SearchForm = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  return (
    <form onClick={handleSubmit} className="search-form d-none d-lg-block">
      <input type="text" placeholder="Search here..." />
      <button className="tran3s">
        <i className="bi bi-search"></i>
      </button>
    </form>
  );
};

export default SearchForm;
