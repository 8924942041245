import React, { useState } from "react";
import { getLang } from "../../assets/dummyData/langs";
import { useSelector } from "react-redux";

const TextBlock = () => {
	const language = useSelector((state) => state.language.value);
	const [langs] = useState(getLang());
   
	return (
		<div className="vcamp-text-block-nine pt-180 lg-pt-120">
			<div className="container">
				<div className="row gx-xl-5">
					<div className="col-lg-7">
						<div className="title-style-seven md-pb-10">
							<h2 className="title">{langs.get("lb_aboutUsTitle")[language]}</h2>
						</div>
					</div>

					<div className="col-lg-5">
						<div className="text-wrapper">
							<p className="text-lg">{langs.get("lb_aboutUsSubtitle")[language]}</p>
							<p className="text-lg pb-1">{langs.get("lb_aboutUsAuthSubtitle")[language]} </p>
							<h6 className=" text-center">Owe like <span className="text-dark">o</span>ur <span className="text-dark">we</span>bsite</h6>
							<h6 className="name">
								Krasnansky Michal. <span>CEO of OWE</span>
							</h6>
						</div>
						{/* <!-- /.text-wrapper --> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TextBlock;
