import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	value: localStorage.getItem("language") ? localStorage.getItem("language") : "sk",
};

export const languageSlice = createSlice({
	name: "language",
	initialState,
	reducers: {
		changeLanguage: (state, { payload }) => {
			state.value = payload;
			localStorage.setItem("language", state.value);
		},
	},
});

export const { changeLanguage } = languageSlice.actions;

export default languageSlice.reducer;