import React from "react";

const CopyrightSocial = () => {
   const socialContent = [
      {
         id: 1,
         iconName: "fab fa-facebook-f",
         socialLink: "https://www.facebook.com/groups/849407753525963",
      },
      {
         id: 2,
         iconName: "bi bi-instagram",
         socialLink: "https://www.instagram.com/owe.krasnansky/",
      },

   ];

   return (
      <ul className="d-flex justify-content-center justify-content-lg-end style-none">
         {socialContent.map((social) => (
            <li className="px-2 text-primary" key={social.id}>
               <a href={social.socialLink} target="_blank" rel="noopener noreferrer">
                  <i className={` ${social.iconName}`}></i>
               </a>
            </li>
         ))}
      </ul>
   );
};

export default CopyrightSocial;
