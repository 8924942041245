import axios from "axios";

let link;
if (process.env.REACT_APP_NODE_ENV !== "prod") {
	link = "http://localhost:5000/";
} else {
	link = "https://cv-23-backend.onrender.com/";
}


export const sendContactMail = async (objSend) => {
	let getLink = `${link}sendMail/owecontact`;
	try {
		const result = await axios
			.post(getLink, {
				headers: {
					"Content-Type": "application/json",
				},
				objSend,
			})
			.catch((error) => {
				return 500;
			});
		return result;
	} catch (error) {
		return error;
	}
};

export const checkToken = async () => {
	let getLink = `${link}users/check`;
	let token = sessionStorage.getItem("token");
	try {
		const response = await axios.get(getLink, {
			headers: {
				Authorization: token,
			},
		});
		const result = await { status: response.status, user: response.data.user };
		return result;
	} catch (err) {}
};

export const checkGoogleAuth = async (data) => {
	let getLink = `${link}google/auth`;
	try {
		const result = await axios
			.post(getLink, {
				headers: {
					"Content-Type": "application/json",
				},
				data,
			})
			.catch((error) => {
				return { status: 500, user: error };
			});
		console.log(result);

		if (result.status === 200) {
			return await googleRegOrLogin(result.data);
		}
	} catch (error) {
		return { status: 500, user: error };
	}
};
export const googleRegOrLogin = async (data) => {
	let getLink = `${link}google/regorlogin`;
	try {
		const result = await axios
			.post(getLink, {
				headers: {
					"Content-Type": "application/json",
				},
				data,
			})
			.catch((error) => {
				console.log("ERR REG OR LOGIN");
				return { status: error.response.status, message: error.response.statusText };
			});
		if (result.data.token) {
			sessionStorage.setItem("token", result.data.token);
		}
		return result;
	} catch (error) {
		console.log("ERR TRI CATCH");
		return error;
	}
};
