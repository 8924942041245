import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getLang } from "../../assets/dummyData/langs";
import LoginForm from "../common/form/LoginForm";
import SignupForm from "../common/form/SignupForm";
import Popup from "../common/video-popup/Popup";

const Hero2 = () => {
	const language = useSelector((state) => state.language.value);
	const [inRegistration, setInRegistration] = useState(false);
	const [langs] = useState(getLang());
	const [singOrLogIn, setSingOrLogIn] = useState(true);
	const [textIn, setTextIn] = useState({ logText: langs.get("lb_singup")[language], questText: langs.get("lb_notAMember")[language], keepText: langs.get("lb_keepLogin")[language], forgetText: langs.get("lb_forgetPass")[language] });
	const logedUser = useSelector((state) => state.logedUser.value);

	const handleClickChangeLogIn = () => {
		setInRegistration(!inRegistration);
		if (singOrLogIn) {
			setTextIn({
				idText: langs.get("lb_singup")[language],
				logText: langs.get("lb_login")[language],
				questText: langs.get("lb_notAMember")[language],
				keepText: langs.get("lb_keepLogin")[language],
				forgetText: langs.get("lb_forgetPass")[language],
			});
		} else {
			setTextIn({
				idText: langs.get("lb_login")[language],
				logText: langs.get("lb_singup")[language],
				questText: langs.get("lb_notAMember")[language],
				keepText: langs.get("lb_keepLogin")[language],
				forgetText: langs.get("lb_forgetPass")[language],
			});
		}
		setSingOrLogIn(!singOrLogIn);
	};

	useEffect(() => {
		setTextIn({ logText: langs.get("lb_singup")[language], questText: langs.get("lb_notAMember")[language], keepText: langs.get("lb_keepLogin")[language], forgetText: langs.get("lb_forgetPass")[language] });
	}, [language, langs]);
	return (
		<React.Fragment>
			<div id="join-us" className="  pt-1">
				<div className="hero-banner-eight mt-225 md-mt-150 ">
					<div className="container">
						{!logedUser && (
							<div className="row">
								<div className="col-12">
									<p className="term-text">
										{!inRegistration && textIn.questText}
										<Link to={() => void 0} onClick={handleClickChangeLogIn} className="ms-2">
											{textIn.logText}
										</Link>
									</p>
								</div>
							</div>
						)}
						<div className="row">
							{!logedUser ? (
								<div className="col-lg-6 ">{singOrLogIn ? <LoginForm textIn={{ ...textIn, logText: langs.get("lb_login")[language] }} /> : <SignupForm textIn={textIn} />}</div>
							) : (
								<div className="col-lg-6 ">
									<h2 className="hero-heading">{langs.get("lb_youAreIn")[language]}</h2>
									<p className="hero-sub-heading text-lg">{logedUser}</p>
								</div>
							)}
							<div className="col-lg-5 ms-auto">
								<div className="d-flex align-items-center">
									<h2 className="hero-heading">{langs.get("lb_videoTitle")[language]}</h2>
									<Popup />
								</div>
								<p className="hero-sub-heading text-lg">{langs.get("lb_videoSubtitle")[language]}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Hero2;
