import React, { useState } from "react";
import { Link } from "react-router-dom";
import FooterNewsletter from "./FooterNewsletter";
import { getLang } from "../../../assets/dummyData/langs";
import { useSelector } from "react-redux";

const FooterMenuListTwo = ({ className = "" }) => {
	const [langs] = useState(getLang());
	const language = useSelector((state) => state.language.value);
	const menuContent = [
		{ name: langs.get("lb_menuAboutUs")[language], routeLink: "#about" },
		{ name: langs.get("lb_menuJoinUs")[language], routeLink: "#join-us" },
		{ name: langs.get("lb_menuGallery")[language], routeLink: "#gallery" },
		{ name: langs.get("lb_menuContact")[language], routeLink: "#contact" },
	];

	const footerMenuContent = [
		{
			id: 1,
			title: "Links",
			colClass: "col-lg-2",
			list: menuContent,
		},
		{
			id: 2,
			title: "Legal",
			colClass: "col-lg-3",
			list: [
				{ name: langs.get("lb_TermsOfUse")[language], routeLink: "/" },
				{ name: langs.get("lb_TermsConditions")[language], routeLink: "/" },
				{ name: langs.get("lb_PrivacyPolicy")[language], routeLink: "/" },
				{ name: langs.get("lb_CookiePolicy")[language], routeLink: "/" },
			],
		},
	];

	return (
		<>
			{footerMenuContent.map((menuItem) => (
				<div className={`${menuItem.colClass} col-md-3 col-sm-6 mb-40 text-center text-md-start`} key={menuItem.id}>
					<h5 className="title">{menuItem.title}</h5>
					<ul className="footer-list style-none">
						{menuItem.list.map((list, i) => (
							<li key={i}>
								{menuItem.id === 1 ? (
									<a className="nav-link" href={list.routeLink}>
										{list.name}
									</a>
								) : (
									<Link to={list.routeLink}>{list.name}</Link>
								)}
							</li>
						))}
					</ul>
				</div>
			))}

			<div className="col-lg-5 col-md-6 mb-40 text-center text-md-start">
				<FooterNewsletter />
			</div>
		</>
	);
};

export default FooterMenuListTwo;
