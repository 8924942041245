import React, { useState } from "react";
import CopyrightSocial from "../common/footer/CopyrightSocial";
import img2 from "../../assets/images/img/jaajam.jpg";
import { getLang } from "../../assets/dummyData/langs";
import { useSelector } from "react-redux";

export const AboutMe = () => {
	const [langs] = useState(getLang());
	const language = useSelector((state) => state.language.value);

	return (
		<div className="vcamp-text-block-nine pt-180 pb-180 lg-pt-120 lg-pb-100">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 col-md-8 col-sm-11">
						<div className="text-wrapper">
							<h2>Michal Krasňanský</h2>
							<p className="text-lg">{langs.get("lb_startStudioMission")[language]}</p>
							<CopyrightSocial />
						</div>
					</div>
				</div>
			</div>

			<img src={img2} alt="illustration" className="illustration shapes rounded-circle opacity-75" data-aos="fade-left" />
		</div>
	);
};
