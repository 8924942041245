import { useGoogleLogin } from "@react-oauth/google";
import { motion } from "framer-motion";
import React from "react";
import { checkGoogleAuth } from "../../assets/dummyData/axiosCalls";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/features/logedUser-slice";

export const OauthGoogleButton = (props) => {
	const dispatch = useDispatch();

	const googleLogin = useGoogleLogin({
		flow: "auth-code",
		onSuccess: async (codeResponse) => {
			const res = await checkGoogleAuth(codeResponse);
			if ((res.status && res.status === 200) || (res.status && res.status === 204)) {
				dispatch(setUser(res.data.user));
				localStorage.setItem("logedUser", res.data.user);
				console.log(res, "OK");
			} else {
				console.log(res, "ERR");
			}
		},
		onError: (errorResponse) => console.log(errorResponse),
	});
	return (
		<>
			<div
				style={{
					position: "absolute",
					top: "100%",
					left: "50%",
					transform: "translate(-50%,-50%)",
					zIndex: 2000,
				}}
			>
				<motion.div
					initial={{ opacity: 0, scale: 0.5 }}
					animate={{ opacity: 1, scale: 1 }}
					transition={{
						duration: 0.8,
						delay: 0.5,
						ease: [0, 0.71, 0.2, 1.01],
					}}
				>
					{/* <div className="alert alert-danger">eeer</div> */}
				</motion.div>
			</div>
			<img onClick={googleLogin} width="50" src={require("../../assets/images/svg/Google__G__Logo.svg").default} alt="icon" />
			{/* {linearPreloade && <LinearProgress color="success" sx={{ width: "100%", bottom: -10, position: "absolute" }} />} */}
		</>
	);
};
